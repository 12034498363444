import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

import { FormReferenceFragment } from '../../../graphql-types';
import FormAdapter from '../FormAdapter';

interface FormModalProps {
  formRef: FormReferenceFragment;
  open: boolean;
  onClose: () => void;
}

const FormModal: React.FC<FormModalProps> = ({ formRef, open, onClose }) => {
  return (
    <Modal
      isCentered
      isOpen={open}
      onClose={onClose}
      scrollBehavior="inside"
      size={{ base: 'md', md: '3xl' }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          <FormAdapter data={formRef} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
